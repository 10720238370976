const water = [
    "239649", "529847"
];
const veggies = [
    "296078", "296075", "296072", "242235", "241874",
    "240416", "240412", "240404", "240388", "240379",
    "240374", "781408", "781409", "781406", "781413", "781411"
];

const farming_materials = [
    ...water, "239641", "529850", "641148"
];

const compost = [
    "318739", "707628"
];

const other_food = [
    '250073', '383313', '383213', '349209', '289214',
    '250071', '249915', '546475', '548279', '616602',
    '637428', '641165', '659966', '709929', '720987',
    '458160', '720990', '720994', '758144', '764626',
    '764635', '781405', '788653', '788644', '788669',
    '788673'
];

const coins = [
    '242665', '429719'
];

const ca_materials = [
    '242727', '529826', '529844', '345444', '478748', '536883',
    '318739', '529846', '598336', '265138', '529841', '478750', '478751',
    '318738', '390556', '529843', '265148', '289228', '383214', '383215',
    '289221', '265136', '529834', '478753', '478749', '478752', '478754',
    '581413', '478755', '410659', '529825', '529830', '529829', '600807',
    '529836', '529848', '454902', '383217', '529840', '429700',
    '289233', '383218', '596138', '529838', '318740', '289225', '529847',
    '429664', '429660', '345440', '529828', '265143', '345441', '429671',
    '430264', '529845', '709290', '709292', '709293', '788650', '788652',
    '788653', '788644', '788666', '788669', '788670', '788671', '788672',
    '788673', '788674', '788675', '788676', '788677', '788678', '788679',
    '788680', '788681', '788682', '788683', '788684', '788690', '788691',
    '350709', '345443'
];


const slimeys = [
    '409836', '409837', '409833', '409834', '409835'
];
const goo = [
    '416533', '416529', '416536', '416531', '416532'
];
const shard = [
    '529816', '529817', '529818', '529819', '529820'
];
const fire = [
    "390556"
];
const commissioner = [
    "402976",
    "529841",
    "265136",
    ...fire,
    "318738",
    "478751",
    "289228",
    "289221",
    "265138",
    "289233",
    "289225",
    "265148",
    "383214",
    "529843",
    "529828",
    "383215",
    "242727",
    "345440",
    "402981",
    ...coins
];

const bills = [
    "795628", // 1000
    "795100", // 100
];

const collectors = [
    '788589', '788588', '453452', '265153'
];

const collector_tools = [
    '329778', '329779', '329782', '329783',
    '329781', '329784', '329785',
    '533769', '429516', '478748'
];

export const filterOptions = {
    farm: [
        ...farming_materials, // MATERIALS
        "402770", "402773", // 5 packS
        ...compost, // cOMPOST
        ...veggies,
        ...coins
    ],
    ca: [
        ...veggies,
        ...other_food,
        ...ca_materials,
        ...coins
    ],       // Template IDs for "City Deposit"
    magic: [
        ...slimeys,
        ...coins,
        ...other_food,
        ...veggies,
        ...goo,
        ...shard,
        ...water

    ],     // Template IDs for "Battle Deposit"
    blend: [
        ...commissioner
    ],  // Template IDs for "Adventure Deposit"
    ptfi: [
        ...bills
    ],       // Template IDs for "Mine Deposit"
    staking: [
        ...collectors,
        ...collector_tools
    ]     // Template IDs for "Staking"
};