// Card.js
import React from 'react';
import './css/Card.css'; // Assuming you have this CSS in the same directory

const Card = ({ asset, onSelect, isSelected = false, small = false }) => {
    const handleCardClick = () => {
        onSelect(asset.asset_id); // Pass the asset ID on click
    };

    const cardClassName = `asset-card ${small ? 'asset-card-small' : ''} ${isSelected ? 'selected' : ''}`;

    return (
        <div className={cardClassName} onClick={handleCardClick}>
            <p className="mint-number">#{asset.template_mint}</p>
            {asset.data.video ? (
                <video
                    src={`https://atomichub-ipfs.com/ipfs/${asset.data.video}`}
                    alt={asset.name}
                    className={`nft-video ${small ? 'nft-video-small' : ''}`}
                    autoPlay
                    loop
                    muted
                />
            ) : (
                <img
                    src={`https://atomichub-ipfs.com/ipfs/${asset.data.img}`}
                    alt={asset.name}
                    className={`nft-image ${small ? 'nft-image-small' : ''}`}
                />
            )}
            <p className="asset-name">{asset.name}</p>
        </div>
    );
};

export default Card;
