import {
    Link,
    //useLocation 
} from 'react-router-dom';
import './css/Navbar.css';
import waxLogo from './assets/wax.png';
import ncLogo from './assets/logo.png';


const Navbar = ({ session, balance, handleLogout }) => {
    // const location = useLocation(); // Get the current route

    return (
        <nav className="navbar">
            <div className="navbar-left">
                <Link to="/" className="navbar-logo">
                    <img src={ncLogo} alt="PT Logo" width="40" height="40" style={{ marginRight: '5px' }} />
                    PixelTycoons
                </Link>
            </div>

            <div className="navbar-center">

                {/*<Link to="/" className={"navbar-link-a"}>
                    <p className='navbar-link'>NFTs</p>
                </Link>
                */}
                <a className='navbar-link-a' href="https://nfthive.io/drops?collection=pixeltycoons" target="_blank" rel="noopener noreferrer">
                    <p className='navbar-link'>
                        Drops
                    </p>
                </a>


            </div>

            <div className="navbar-right">
                <div className='user-info-bals'>
                    <h4>{String(session.actor)}</h4>
                    <div className="user-bals">

                        <h5>
                            {balance && typeof balance === 'object'
                                ? (
                                    <>
                                        <img src={waxLogo} alt="WAX Logo" width="15" height="15" style={{ marginLeft: '5px', marginRight: '5px' }} />
                                        {balance.wax}
                                    </>
                                )
                                : "0.0000 WAX"}
                        </h5>

                    </div>




                </div>
                <button className="logout-button" onClick={handleLogout}>Logout</button>


            </div>
        </nav >
    );
};



export default Navbar;
