import React from 'react';
import { mainnetSessionKit } from '../authService';
import "../css/App.css"
import { trackEvent } from '../../analytics';

const Login = ({ onLogin, setNetwork, fetchBalance, fetchAssets, setBalance, setAssets }) => {
  const handleLogin = async () => {
    try {
      const { session } = await mainnetSessionKit.login();

      // Call the onLogin callback to set session
      onLogin(session);

      // Set the network to mainnet (or whatever logic you need here)
      setNetwork('mainnet');
      trackEvent('login', 'Login Session', 'Connect Wallet', session.actor);
      // Fetch the user's balance and assets after login
      const fetchedBalance = await fetchBalance(session);
      setBalance(fetchedBalance);

      const fetchedAssets = await fetchAssets(session.actor);
      setAssets(fetchedAssets);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <button className='Login' onClick={handleLogin}>Login</button>
  );
};

export default Login;
