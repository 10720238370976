// src/authService.js
import { SessionKit } from "@wharfkit/session";
import { WebRenderer } from "@wharfkit/web-renderer";
import { WalletPluginAnchor } from "@wharfkit/wallet-plugin-anchor";
import { WalletPluginCloudWallet } from '@wharfkit/wallet-plugin-cloudwallet';

const webRenderer = new WebRenderer();

// Mainnet Session Kit
const mainnetSessionKit = new SessionKit({
  appName: "YourAppName",
  chains: [
    {
      id: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4", // Mainnet chain ID
      url: "https://wax.greymass.com", // Mainnet endpoint
    },
  ],
  ui: webRenderer,
  walletPlugins: [new WalletPluginCloudWallet(), new WalletPluginAnchor()],
});

// Testnet Session Kit
const testnetSessionKit = new SessionKit({
  appName: "YourTestnetAppName",
  chains: [
    {
      id: "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12", // Testnet chain ID
      url: "https://waxtestnet.greymass.com", // Testnet endpoint
    },
  ],
  ui: webRenderer,
  walletPlugins: [new WalletPluginAnchor()],
});

// Export both session kits
export { mainnetSessionKit, testnetSessionKit };
