// Popup.js
import React from 'react';
import './css/Popup.css'; // Import your CSS for styling
import check from "./assets/check.jpg"
const Popup = ({ transactionLink, onClose }) => {
    return (
        <div className="popup-overlay" onClick={onClose}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <img src={check} alt="transaction successful" width="100" height="100" style={{ marginLeft: '5px', marginRight: '5px' }} />
                <h2>Success!</h2>
                <p>
                    View it <a href={transactionLink} target="_blank" rel="noopener noreferrer">here</a>.
                </p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default Popup;
