import React, { useState, useEffect, useCallback } from 'react';
import "./css/Landing.css";
import Navbar from './widgets/Navbar';
import Card from "./widgets/Card";
import { filterOptions } from "./constants.js"; // Assuming this exports a dictionary of template IDs for each type
import { ContractKit } from '@wharfkit/contract';
import { getDynamicContent } from "./funcs/dynamicTexts.js";
import { trackEvent } from '../analytics.js';
import { useParams, useNavigate } from 'react-router-dom';
import Foot from './widgets/Footer.js';

const NFT = ({
    session,
    handleLogout,
    balance,
    schemas,
    filteredAssets,
    schemaFilter,
    setSchemaFilter,
    onSelect,
    selectedAssets,
    assets,
    setTransactionHash,
    setShowPopup,
    setSelectedAssets,
    refreshAssets,
    visibleAssets,
    setVisibleAssets
}) => {
    const [activeFilter, setActiveFilter] = useState(null); // Active filter type (e.g., "farm")
    const [availableSchemas, setAvailableSchemas] = useState([]); // Track available schemas based on the active filter
    const [hasFarmingLicense, setHasFarmingLicense] = useState(false);
    const { filterType } = useParams(); // Get the filter type from the URL
    const navigate = useNavigate();



    // Update available schemas based on active filter

    const updateAvailableSchemas = useCallback((filterKey) => {
        const allowedTemplates = filterOptions[filterKey] || [];
        const filtered = filteredAssets.filter(asset =>
            allowedTemplates.includes(asset.template.template_id)
        );
        const uniqueSchemas = [...new Set(filtered.map(asset => asset.schema.schema_name))];
        setAvailableSchemas(uniqueSchemas);
    }, [filteredAssets, setAvailableSchemas]);

    // Function to handle filtering based on the selected button
    const applyFilter = useCallback((filterKey) => {
        console.log('Applying filter:', filterKey); // Debugging
        setActiveFilter(filterKey); // Set the active filter based on the button clicked
        setVisibleAssets(12); // Reset the visible asset count when filter changes
        updateAvailableSchemas(filterKey); // Update available schemas based on the selected filter
        setSchemaFilter(null); // Reset the schema filter when applying a new active filter
    }, [setActiveFilter, setVisibleAssets, updateAvailableSchemas, setSchemaFilter]);

    // Function to clear the filter and show all buttons again
    const clearFilter = useCallback(() => {
        console.log('Clearing filter'); // Debugging
        setActiveFilter(null);
        setVisibleAssets(12);
        setAvailableSchemas([]); // Reset available schemas when clearing the filter
        setSelectedAssets([]);
        navigate('/'); // Navigate back to the main URL
    }, [navigate, setActiveFilter, setVisibleAssets, setAvailableSchemas, setSelectedAssets]);

    const applyFilterAndNavigate = (filterKey) => {
        setActiveFilter(filterKey);
        applyFilter(filterKey);
        navigate(`/nft/${filterKey}`); // Update the URL
    };

    // Check for matching assets and set the farming license state
    useEffect(() => {
        const matchingAssets = assets.filter(asset => asset.template && asset.template.template_id === "374415");
        setHasFarmingLicense(matchingAssets.length > 0);

        if (filterType) {
            setActiveFilter(filterType);
            applyFilter(filterType); // Automatically apply the filter based on the URL
        } else {
            clearFilter();
        }
    }, [assets, filterType, applyFilter, setActiveFilter, clearFilter]);

    console.log(hasFarmingLicense);



    // Filter the assets based on the active filter type
    const getFilteredAssets = () => {
        if (!activeFilter) return []; // Return an empty array if no filter is selected
        const allowedTemplates = filterOptions[activeFilter] || []; // Get template IDs for the active filter

        // Filter assets based on active filter, template, and farming license only for the "farm" section
        return filteredAssets.filter(asset => {
            const isAllowedTemplate = allowedTemplates.includes(asset.template.template_id);

            // Only apply the "vegkingdom" restriction if active filter is "farm" and user lacks the farming license
            const isVegkingdomAsset = asset.schema && asset.schema.schema_name === "vegkingdom";
            const canShowVegkingdomAsset = !(activeFilter === "farm" && isVegkingdomAsset && !hasFarmingLicense);

            return isAllowedTemplate && canShowVegkingdomAsset;
        });
    };


    // Function to load more assets
    const loadMoreAssets = () => {
        setVisibleAssets(prev => prev + 12); // Load more assets when requested
    };

    console.log('Active Filter:', activeFilter); // Debugging



    const handleDepositNFTs = async (memo) => {
        const contractKit = new ContractKit({
            client: session.client,
        });
        const address = activeFilter === "staking" ? "pixelstaking" : "pixeltycoons";

        const contract = await contractKit.load("atomicassets");

        // Create a list of actions
        const action = contract.action("transfer", {
            from: session.actor,
            asset_ids: selectedAssets,
            memo: activeFilter,
            to: address
        });

        try {
            // Perform all actions in a single transaction
            const result = await session.transact({ action });
            const txHash = result.response.transaction_id; // Get the transaction hash
            setTransactionHash(txHash); // Set the transaction hash for the popup

            // Set the transaction link (adjust this URL as necessary)
            const transactionLink = `https://waxblock.io/transaction/${txHash}`;
            setShowPopup(true, transactionLink); // Show the popup and set transaction link

            setSelectedAssets([]); // Deselect assets

            // Refresh assets after burning
            await refreshAssets();

            console.log("Transaction successful", result);
            trackEvent('deposit-stake', activeFilter, 'Success', session.actor);
        } catch (error) {
            console.error("Transaction failed", error);
            trackEvent('deposit-stake', activeFilter, 'Fail', session.actor);
        }
    };


    return (
        <div className='Landing'>
            <Navbar handleLogout={handleLogout} session={session} balance={balance} />

            {/* Display Transfer Buttons or Back Button based on active filter */}
            <div className="transfer-buttons">

                {activeFilter ? (
                    <div>
                        <div className='return-deposit-stake-button'>
                            <div className="schema-filter">
                                <label htmlFor="schema-select">Filter by Schema:</label>
                                <select
                                    id="schema-select"
                                    value={schemaFilter || ''}
                                    onChange={(e) => setSchemaFilter(e.target.value)}
                                >
                                    <option value="">All</option>
                                    {/* Only show available schemas based on the active filter */}
                                    {availableSchemas.map((schema, index) => (
                                        <option key={index} value={schema}>
                                            {schema}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <button className="return-button" onClick={clearFilter}>Return</button>

                            <button
                                className="deposit-button"
                                onClick={handleDepositNFTs}
                            >
                                {activeFilter === "staking" ? "Stake" : "Deposit"}
                            </button>
                        </div>
                        <div className="dynamic-content">
                            {getDynamicContent(activeFilter)}
                        </div>
                    </div>

                ) : (
                    <div className="niche-buttons">
                        <div className="niche-buttons2">
                            <>
                                <button className='transfer-button' onClick={() => applyFilterAndNavigate("farm")}>Farm Deposit</button>
                                <button className='transfer-button' onClick={() => applyFilterAndNavigate("ca")}>CA Deposit</button>
                                <button className='transfer-button' onClick={() => applyFilterAndNavigate("magic")}>MG Deposit</button>


                            </>
                        </div>
                        <div className="niche-buttons2">
                            <>

                                <button className='transfer-button' onClick={() => applyFilterAndNavigate("ptfi")}>PT FI Deposit</button>
                                <button className='transfer-button' onClick={() => applyFilterAndNavigate("blend")}>Commissioner Deposit</button>
                                <button className='transfer-button' onClick={() => applyFilterAndNavigate("staking")}>Stake CA</button>

                            </>
                        </div>
                    </div>
                )}
            </div>



            <div className="Landing-Container">
                <div className="assets-grid-dashboard">
                    {/* Render assets based on the active filter only if one is selected */}
                    {activeFilter && getFilteredAssets().slice(0, visibleAssets).map((asset) => (
                        <Card
                            key={asset.asset_id}
                            asset={asset}
                            onSelect={onSelect}
                            isSelected={selectedAssets.includes(asset.asset_id)}
                        />
                    ))}
                </div>

                {activeFilter && visibleAssets < getFilteredAssets().length && (
                    <button className="load-more" onClick={loadMoreAssets}>
                        Load More Assets
                    </button>
                )}
            </div>
            <Foot />
        </div>
    );
};

export default NFT;
