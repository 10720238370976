export const trackEvent = (eventName, eventCategory, eventLabel, walletAddress = null) => {
    if (typeof window !== 'undefined' && window.gtag) {
        const eventData = {
            event_category: eventCategory,
            event_label: eventLabel,
        };

        // Only include wallet address if provided
        if (walletAddress) {
            eventData.wallet_address = walletAddress;
        }

        window.gtag('event', eventName, eventData);
    } else {
        console.warn('Google Analytics is not initialized');
    }
};
