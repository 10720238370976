export const getDynamicContent = (activeFilter) => {
    switch (activeFilter) {
        case "farm":
            return farmText();
        case "ca":
            return caText();
        case "magic":
            return mgText();
        case "ptfi":
            return ptfiText();
        case "blend":
            return blendText();
        case "staking":
            return stakeText();
        default:
            return <p>Select an option to start depositing assets.</p>;
    }
};

const farmText = () => {
    return <p>Welcome to Farm Deposit! Here you can deposit your farming assets.</p>;
};

const caText = () => {
    return <p>CA Deposit Section - Deposit assets for collector adventures here.</p>;
};
const mgText = () => {
    return <p>MG Deposit - Here you can deposit magical assets.</p>;
};
const ptfiText = () => {
    return <p>PT FI Deposit - Deposit Bank Notes.</p>;
};
const blendText = () => {
    return <p>Commissioner Deposit - Send assets for commissioner blends.</p>;
};
const stakeText = () => {
    return <p>Stake CA - Stake your collector adventure game keys.</p>;
};