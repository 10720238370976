import { ContractKit } from '@wharfkit/contract';

export const fetchBalance = async (session) => {
    if (!session) return null;

    try {
        const contractKit = new ContractKit({ client: session.client });

        // Load the two contracts
        const wax = await contractKit.load("eosio.token");
        const sexy = await contractKit.load("nightclub.gm");

        // Fetch balances
        const [waxBal, sexyBal] = await Promise.all([
            wax.table("accounts", session.actor).get(0),
            sexy.table("accounts", session.actor).get(0)
        ]);

        // Check if balances were found
        return {
            wax: waxBal ? waxBal.balance.value.toFixed(2) : '0.00', // Return '0.0000' if not found
            sexy: sexyBal ? sexyBal.balance.value.toFixed(2) : '0.00' // Return '0.0000' if not found
        };
    } catch (error) {
        console.error('Error fetching balance:', error);
        return { wax: '0.0000', sexy: '0.0000' }; // Return default values on error
    }
};
